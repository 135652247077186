import firstTabEn from '../../images/phase_2/first_tab_content/first_tab_content_en.webp';
import firstTabJa from '../../images/phase_2/first_tab_content/first_tab_content_ja.webp';
import firstTabKo from '../../images/phase_2/first_tab_content/first_tab_content_ko.webp';
import firstTabCnZh from '../../images/phase_2/first_tab_content/first_tab_content_zh-cn.webp';
import firstTabCnTw from '../../images/phase_2/first_tab_content/first_tab_content_zh-tw.webp';

// Import image for s../econd tab content
import secondTabEn from '../../images/phase_2/second_tab_content/content/second_tab_content_en.webp';
import secondTabJa from '../../images/phase_2/second_tab_content/content/second_tab_content_ja.webp';
import secondTabKo from '../../images/phase_2/second_tab_content/content/second_tab_content_ko.webp';
import secondTabCnZh from '../../images/phase_2/second_tab_content/content/second_tab_content_zh-cn.webp';
import secondTabCnTw from '../../images/phase_2/second_tab_content/content/second_tab_content_zh-tw.webp';

// Import image for a../pply content
import applyKoContentEn from '../../images/phase_2/apply_k/content/apply_k_en.webp';
import applyKoContentJa from '../../images/phase_2/apply_k/content/apply_k_ja.webp';
import applyKoContentKo from '../../images/phase_2/apply_k/content/apply_k_ko.webp';
import applyKoContentCnZh from '../../images/phase_2/apply_k/content/apply_k_zh-cn.webp';
import applyKoContentCnTw from '../../images/phase_2/apply_k/content/apply_k_zh-tw.webp';

import applyCnContentEn from '../../images/phase_2/apply_c/content/apply_c_en.webp';
import applyCnContentJa from '../../images/phase_2/apply_c/content/apply_c_ja.webp';
import applyCnContentKo from '../../images/phase_2/apply_c/content/apply_c_ko.webp';
import applyCnContentCnZh from '../../images/phase_2/apply_c/content/apply_c_zh-cn.webp';
import applyCnContentCnTw from '../../images/phase_2/apply_c/content/apply_c_zh-tw.webp';

// Import ta bar i../mages
import tabBarFirstEn from '../../images/phase_2/tab_bar/first_selected/tabs_en.webp';
import tabBarFirstJa from '../../images/phase_2/tab_bar/first_selected/tabs_ja.webp';
import tabBarFirstKo from '../../images/phase_2/tab_bar/first_selected/tabs_ko.webp';
import tabBarFirstCnZh from '../../images/phase_2/tab_bar/first_selected/tabs_zh-cn.webp';
import tabBarFirstCnTw from '../../images/phase_2/tab_bar/first_selected/tabs_zh-tw.webp';

import tabBarSecondEn from '../../images/phase_2/tab_bar/second_selected/tabs_en.webp';
import tabBarSecondJa from '../../images/phase_2/tab_bar/second_selected/tabs_ja.webp';
import tabBarSecondKo from '../../images/phase_2/tab_bar/second_selected/tabs_ko.webp';
import tabBarSecondCnZh from '../../images/phase_2/tab_bar/second_selected/tabs_zh-cn.webp';
import tabBarSecondCnTw from '../../images/phase_2/tab_bar/second_selected/tabs_zh-tw.webp';

// Import commo default F../AQ images
import faqDefaultEn from '../../images/phase_2/faq_closed_buttons/faq_closed_btn_non_ja.webp';
import faqDefaultJa from '../../images/phase_2/faq_closed_buttons/faq_closed_btn_ja.webp';
import faqDefaultKo from '../../images/phase_2/faq_closed_buttons/faq_closed_btn_non_ja.webp';
import faqDefaultCnZh from '../../images/phase_2/faq_closed_buttons/faq_closed_btn_non_ja.webp';
import faqDefaultCnTw from '../../images/phase_2/faq_closed_buttons/faq_closed_btn_non_ja.webp';

// Import Secon Tab e../xpanded FAQ images
import secondTabFaqExpandedEn from '../../images/phase_2/second_tab_content/faq/faq_expanded_en.webp';
import secondTabFaqExpandedJa from '../../images/phase_2/second_tab_content/faq/faq_expanded_ja.webp';
import secondTabFaqExpandedKo from '../../images/phase_2/second_tab_content/faq/faq_expanded_ko.webp';
import secondTabFaqExpandedCnZh from '../../images/phase_2/second_tab_content/faq/faq_expanded_zh-cn.webp';
import secondTabFaqExpandedCnTw from '../../images/phase_2/second_tab_content/faq/faq_expanded_zh-tw.webp';

// Import Appl expanded F../AQ images
import applyKoFaqExpandedEn from '../../images/phase_2/apply_k/faq/apply_k_faq_expanded_en.webp';
import applyKoFaqExpandedJa from '../../images/phase_2/apply_k/faq/apply_k_faq_expanded_ja.webp';
import applyKoFaqExpandedKo from '../../images/phase_2/apply_k/faq/apply_k_faq_expanded_ko.webp';
import applyKoFaqExpandedCnZh from '../../images/phase_2/apply_k/faq/apply_k_faq_expanded_zh-cn.webp';
import applyKoFaqExpandedCnTw from '../../images/phase_2/apply_k/faq/apply_k_faq_expanded_zh-tw.webp';

import applyCnFaqExpandedEn from '../../images/phase_2/apply_c/faq/apply_c_faq_expanded_en.webp';
import applyCnFaqExpandedJa from '../../images/phase_2/apply_c/faq/apply_c_faq_expanded_ja.webp';
import applyCnFaqExpandedKo from '../../images/phase_2/apply_c/faq/apply_c_faq_expanded_ko.webp';
import applyCnFaqExpandedCnZh from '../../images/phase_2/apply_c/faq/apply_c_faq_expanded_zh-cn.webp';
import applyCnFaqExpandedCnTw from '../../images/phase_2/apply_c/faq/apply_c_faq_expanded_zh-tw.webp';

export const phase2Images = {
    contentImages: {
        first: {
            en: firstTabEn,
            ja: firstTabJa,
            ko: firstTabKo,
            'zh-CN': firstTabCnZh,
            'zh-TW': firstTabCnTw,
        },
        second: {
            en: secondTabEn,
            ja: secondTabJa,
            ko: secondTabKo,
            'zh-CN': secondTabCnZh,
            'zh-TW': secondTabCnTw,
        },
    },
    tabBarImages: {
        first: {
            en: tabBarFirstEn,
            ja: tabBarFirstJa,
            ko: tabBarFirstKo,
            'zh-CN': tabBarFirstCnZh,
            'zh-TW': tabBarFirstCnTw,
        },
        second: {
            en: tabBarSecondEn,
            ja: tabBarSecondJa,
            ko: tabBarSecondKo,
            'zh-CN': tabBarSecondCnZh,
            'zh-TW': tabBarSecondCnTw,
        },
    },
    applyContentImages: {
        ko: {
            en: applyKoContentEn,
            ja: applyKoContentJa,
            ko: applyKoContentKo,
            'zh-CN': applyKoContentCnZh,
            'zh-TW': applyKoContentCnTw,
        },
        cn: {
            en: applyCnContentEn,
            ja: applyCnContentJa,
            ko: applyCnContentKo,
            'zh-CN': applyCnContentCnZh,
            'zh-TW': applyCnContentCnTw,
        },
    },
    faqImages: {
        default: {
            en: faqDefaultEn,
            ja: faqDefaultJa,
            ko: faqDefaultKo,
            'zh-CN': faqDefaultCnZh,
            'zh-TW': faqDefaultCnTw,
        },
        expanded: {
            secondTab: {
                en: secondTabFaqExpandedEn,
                ja: secondTabFaqExpandedJa,
                ko: secondTabFaqExpandedKo,
                'zh-CN': secondTabFaqExpandedCnZh,
                'zh-TW': secondTabFaqExpandedCnTw,
            },
            apply: {
                ko: {
                    en: applyKoFaqExpandedEn,
                    ja: applyKoFaqExpandedJa,
                    ko: applyKoFaqExpandedKo,
                    'zh-CN': applyKoFaqExpandedCnZh,
                    'zh-TW': applyKoFaqExpandedCnTw,
                },
                cn: {
                    en: applyCnFaqExpandedEn,
                    ja: applyCnFaqExpandedJa,
                    ko: applyCnFaqExpandedKo,
                    'zh-CN': applyCnFaqExpandedCnZh,
                    'zh-TW': applyCnFaqExpandedCnTw,
                },
            },
        },
    },
};
