import mnetPlusLogo from '../images/logos/mnet_plus_logo.png';
import { phase1Images } from './image-loader/phase-1';
import { phase2Images } from './image-loader/phase-2';
import { phase3Images } from './image-loader/phase-3';

// Import images for first tab content

import { getCurrentPhase } from './scheduleHelper';

const videoConfig = {
    chinese: {
        first: '//player.bilibili.com/player.html?isOutside=true&bvid=BV1AnUDYGEWx&&p=1',
        second: '//player.bilibili.com/player.html?isOutside=true&bvid=BV1AnUDYGEWx&&p=1',
    },
    default: {
        first: 'https://www.youtube.com/embed/KRaQRiKJUc0',
        second: 'https://www.youtube.com/embed/KRaQRiKJUc0',
    },
};
const PHASE_IMAGES = {
    PHASE_1: phase1Images,
    PHASE_2: phase2Images,
    PHASE_3: phase3Images,
};

const CURRENT_PHASE = getCurrentPhase();

const styleConfig = {
    PHASE_1: {
        firstTab: {
            youtube: {
                en: {
                    top: '16.9%',
                    left: '50%',
                    width: '85.65%',
                    height: '10%',
                },
                ja: {
                    top: '17.4%',
                    left: '50%',
                    width: '85.65%',
                    height: '10.5%',
                },
                ko: {
                    top: '17.5%',
                    left: '50%',
                    width: '85.65%',
                    height: '10.3%',
                },
                'zh-CN': {
                    top: '17.9%',
                    left: '50%',
                    width: '85.65%',
                    height: '10.6%',
                },
                'zh-TW': {
                    top: '17.9%',
                    left: '50%',
                    width: '85.8%',
                    height: '10.5%',
                },
            },
            nextButton: {
                en: {
                    right: '7%',
                    top: '55%',
                    width: '86%',
                    height: '3.6%',
                },
                ko: {
                    right: '7%',
                    top: '53.85%',
                    width: '86%',
                    height: '3.6%',
                },
                ja: {
                    right: '7%',
                    top: '54.4%',
                    width: '86%',
                    height: '3.6%',
                },
                'zh-CN': {
                    right: '7%',
                    top: '54.2%',
                    width: '86%',
                    height: '3.6%',
                },
                'zh-TW': {
                    right: '7%',
                    top: '54.15%',
                    width: '86%',
                    height: '3.6%',
                },
            },
        },
        secondTab: {
            applyButtons: {
                en: {
                    korean: {
                        top: '77%',
                        left: '10.3%',
                        width: '35%',
                        height: '13.4%',
                    },
                    chinese: {
                        top: '77%',
                        right: '10.3%',
                        width: '35%',
                        height: '13.4%',
                    },
                },
                ja: {
                    korean: {
                        top: '75.6%',
                        left: '10.3%',
                        width: '35%',
                        height: '14.2%',
                    },
                    chinese: {
                        top: '75.6%',
                        right: '10.3%',
                        width: '35%',
                        height: '14.2%',
                    },
                },
                ko: {
                    korean: {
                        top: '77.0%',
                        left: '10.5%',
                        width: '35%',
                        height: '13.7%',
                    },
                    chinese: {
                        top: '77.0%',
                        right: '10.5%',
                        width: '35%',
                        height: '13.7%',
                    },
                },
                'zh-CN': {
                    korean: {
                        top: '76.8%',
                        left: '10.5%',
                        width: '35%',
                        height: '15%',
                    },
                    chinese: {
                        top: '76.8%',
                        right: '10.5%',
                        width: '35%',
                        height: '15%',
                    },
                },
                'zh-TW': {
                    korean: {
                        top: '75.3%',
                        left: '10.7%',
                        width: '35%',
                        height: '14.5%',
                    },
                    chinese: {
                        top: '75.3%',
                        right: '10.7%',
                        width: '35%',
                        height: '14.5%',
                    },
                },
            },
        },
        apply: {
            links: {
                en: {
                    link1: {
                        top: '31.7%',
                        left: '11%',
                        width: '37.5%',
                        height: '2.74%',
                        borderRadius: '30px',
                    },
                    link2: {
                        top: '31.7%',
                        right: '11%',
                        width: '37.5%',
                        height: '2.74%',
                        borderRadius: '30px',
                    },
                    link3: {
                        top: '35.3%',
                        left: '11%',
                        width: '37.5%',
                        height: '2.74%',
                        borderRadius: '30px',
                    }, // Japanese - bottom left
                    link4: {
                        top: '35.3%',
                        right: '11%',
                        width: '37.5%',
                        height: '2.74%',
                        borderRadius: '30px',
                    },
                },
                ko: {
                    link1: {
                        top: '30.73%',
                        left: '11%',
                        width: '37.5%',
                        height: '2.74%',
                        borderRadius: '30px',
                    },
                    link2: {
                        top: '30.73%',
                        right: '11%',
                        width: '37.5%',
                        height: '2.74%',
                        borderRadius: '30px',
                    },
                    link3: {
                        top: '34.35%',
                        left: '11%',
                        width: '37.5%',
                        height: '2.74%',
                        borderRadius: '30px',
                    }, // Japanese - bottom left
                    link4: {
                        top: '34.35%',
                        right: '11%',
                        width: '37.5%',
                        height: '2.74%',
                        borderRadius: '30px',
                    },
                },
                ja: {
                    link1: {
                        top: '31.2%',
                        left: '11%',
                        width: '37.5%',
                        height: '2.74%',
                        borderRadius: '30px',
                    },
                    link2: {
                        top: '31.2%',
                        right: '11%',
                        width: '37.5%',
                        height: '2.74%',
                        borderRadius: '30px',
                    },
                    link3: {
                        top: '34.85%',
                        left: '11%',
                        width: '37.5%',
                        height: '2.74%',
                        borderRadius: '30px',
                    }, // Japanese - bottom left
                    link4: {
                        top: '34.85%',
                        right: '11%',
                        width: '37.5%',
                        height: '2.74%',
                        borderRadius: '30px',
                    },
                },

                'zh-CN': {
                    link1: {
                        top: '31.85%',
                        left: '11%',
                        width: '37.5%',
                        height: '2.78%',
                        borderRadius: '30px',
                    },
                    link2: {
                        top: '31.85%',
                        right: '11%',
                        width: '37.5%',
                        height: '2.78%',
                        borderRadius: '30px',
                    },
                    link3: {
                        top: '35.65%',
                        left: '11%',
                        width: '37.5%',
                        height: '2.78%',
                        borderRadius: '30px',
                    }, // Japanese - bottom left
                    link4: {
                        top: '35.65%',
                        right: '11%',
                        width: '37.5%',
                        height: '2.78%',
                        borderRadius: '30px',
                    },
                },
                'zh-TW': {
                    link1: {
                        top: '31.5%',
                        left: '11%',
                        width: '37.5%',
                        height: '2.74%',
                        borderRadius: '30px',
                    },
                    link2: {
                        top: '31.5%',
                        right: '11%',
                        width: '37.5%',
                        height: '2.74%',
                        borderRadius: '30px',
                    },
                    link3: {
                        top: '35.2%',
                        left: '11%',
                        width: '37.5%',
                        height: '2.74%',
                        borderRadius: '30px',
                    }, // Japanese - bottom left
                    link4: {
                        top: '35.2%',
                        right: '11%',
                        width: '37.5%',
                        height: '2.74%',
                        borderRadius: '30px',
                    },
                },
            },
        },
    },
    PHASE_2: {
        firstTab: {
            youtube: {
                en: {
                    top: '18.3%',
                    left: '50%',
                    width: '85.65%',
                    height: '10.8%',
                },
                ko: {
                    top: '19%',
                    left: '50%',
                    width: '85.65%',
                    height: '11%',
                },
                ja: {
                    top: '18.7%',
                    left: '50%',
                    width: '85.65%',
                    height: '11%',
                },
                'zh-CN': {
                    top: '19.3%',
                    left: '50%',
                    width: '85.65%',
                    height: '11.5%',
                },
                'zh-TW': {
                    top: '19.3%',
                    left: '50%',
                    width: '85.65%',
                    height: '11.5%',
                },
            },
            nextButton: {
                en: {
                    right: '7%',
                    top: '57.8%',
                    width: '86%',
                    height: '3.7%',
                },
                ko: {
                    right: '7%',
                    top: '57.2%',
                    width: '86%',
                    height: '3.8%',
                },
                ja: {
                    right: '7%',
                    top: '58.7%',
                    width: '86%',
                    height: '3.8%',
                },
                'zh-CN': {
                    right: '7%',
                    top: '57.2%',
                    width: '86%',
                    height: '4.3%',
                },
                'zh-TW': {
                    right: '7%',
                    top: '57.2%',
                    width: '86%',
                    height: '4.2%',
                },
            },
        },
        secondTab: {
            applyButtons: {
                en: {
                    korean: {
                        top: '77%',
                        left: '10.3%',
                        width: '35%',
                        height: '13.4%',
                    },
                    chinese: {
                        top: '77%',
                        right: '10.3%',
                        width: '35%',
                        height: '13.4%',
                    },
                },
                ja: {
                    korean: {
                        top: '75.6%',
                        left: '10.3%',
                        width: '35%',
                        height: '14.2%',
                    },
                    chinese: {
                        top: '75.6%',
                        right: '10.3%',
                        width: '35%',
                        height: '14.2%',
                    },
                },
                ko: {
                    korean: {
                        top: '77.0%',
                        left: '10.5%',
                        width: '35%',
                        height: '13.7%',
                    },
                    chinese: {
                        top: '77.0%',
                        right: '10.5%',
                        width: '35%',
                        height: '13.7%',
                    },
                },
                'zh-CN': {
                    korean: {
                        top: '76.8%',
                        left: '10.5%',
                        width: '35%',
                        height: '15%',
                    },
                    chinese: {
                        top: '76.8%',
                        right: '10.5%',
                        width: '35%',
                        height: '15%',
                    },
                },
                'zh-TW': {
                    korean: {
                        top: '75.3%',
                        left: '10.7%',
                        width: '35%',
                        height: '14.5%',
                    },
                    chinese: {
                        top: '75.3%',
                        right: '10.7%',
                        width: '35%',
                        height: '14.5%',
                    },
                },
            },
        },
        apply: {
            links: {
                en: {
                    link1: {
                        top: '31.7%',
                        left: '11%',
                        width: '37.5%',
                        height: '2.74%',
                        borderRadius: '30px',
                    },
                    link2: {
                        top: '31.7%',
                        right: '11%',
                        width: '37.5%',
                        height: '2.74%',
                        borderRadius: '30px',
                    },
                    link3: {
                        top: '35.3%',
                        left: '11%',
                        width: '37.5%',
                        height: '2.74%',
                        borderRadius: '30px',
                    }, // Japanese - bottom left
                    link4: {
                        top: '35.3%',
                        right: '11%',
                        width: '37.5%',
                        height: '2.74%',
                        borderRadius: '30px',
                    },
                },
                ko: {
                    link1: {
                        top: '30.73%',
                        left: '11%',
                        width: '37.5%',
                        height: '2.74%',
                        borderRadius: '30px',
                    },
                    link2: {
                        top: '30.73%',
                        right: '11%',
                        width: '37.5%',
                        height: '2.74%',
                        borderRadius: '30px',
                    },
                    link3: {
                        top: '34.35%',
                        left: '11%',
                        width: '37.5%',
                        height: '2.74%',
                        borderRadius: '30px',
                    }, // Japanese - bottom left
                    link4: {
                        top: '34.35%',
                        right: '11%',
                        width: '37.5%',
                        height: '2.74%',
                        borderRadius: '30px',
                    },
                },
                ja: {
                    link1: {
                        top: '31.2%',
                        left: '11%',
                        width: '37.5%',
                        height: '2.74%',
                        borderRadius: '30px',
                    },
                    link2: {
                        top: '31.2%',
                        right: '11%',
                        width: '37.5%',
                        height: '2.74%',
                        borderRadius: '30px',
                    },
                    link3: {
                        top: '34.85%',
                        left: '11%',
                        width: '37.5%',
                        height: '2.74%',
                        borderRadius: '30px',
                    }, // Japanese - bottom left
                    link4: {
                        top: '34.85%',
                        right: '11%',
                        width: '37.5%',
                        height: '2.74%',
                        borderRadius: '30px',
                    },
                },

                'zh-CN': {
                    link1: {
                        top: '31.85%',
                        left: '11%',
                        width: '37.5%',
                        height: '2.78%',
                        borderRadius: '30px',
                    },
                    link2: {
                        top: '31.85%',
                        right: '11%',
                        width: '37.5%',
                        height: '2.78%',
                        borderRadius: '30px',
                    },
                    link3: {
                        top: '35.65%',
                        left: '11%',
                        width: '37.5%',
                        height: '2.78%',
                        borderRadius: '30px',
                    }, // Japanese - bottom left
                    link4: {
                        top: '35.65%',
                        right: '11%',
                        width: '37.5%',
                        height: '2.78%',
                        borderRadius: '30px',
                    },
                },
                'zh-TW': {
                    link1: {
                        top: '31.5%',
                        left: '11%',
                        width: '37.5%',
                        height: '2.74%',
                        borderRadius: '30px',
                    },
                    link2: {
                        top: '31.5%',
                        right: '11%',
                        width: '37.5%',
                        height: '2.74%',
                        borderRadius: '30px',
                    },
                    link3: {
                        top: '35.2%',
                        left: '11%',
                        width: '37.5%',
                        height: '2.74%',
                        borderRadius: '30px',
                    }, // Japanese - bottom left
                    link4: {
                        top: '35.2%',
                        right: '11%',
                        width: '37.5%',
                        height: '2.74%',
                        borderRadius: '30px',
                    },
                },
            },
        },
    },
    PHASE_3: {
        firstTab: {
            youtube: {
                en: {
                    top: '16.2%',
                    left: '50%',
                    width: '85.65%',
                    height: '9.4%',
                },
                ja: {
                    top: '16.7%',
                    left: '50%',
                    width: '85.65%',
                    height: '9.8%',
                },
                ko: {
                    top: '16.5%',
                    left: '50%',
                    width: '85.65%',
                    height: '9.7%',
                },
                'zh-CN': {
                    top: '17.05%',
                    left: '50%',
                    width: '85.72%',
                    height: '10%',
                },
                'zh-TW': {
                    top: '16.9%',
                    left: '50%',
                    width: '85.8%',
                    height: '10%',
                },
            },
            youtube2: {
                en: {
                    top: '44.4%',
                    left: '50%',
                    width: '85.65%',
                    height: '9.4%',
                },
                ja: {
                    top: '43.8%',
                    left: '50%',
                    width: '85.65%',
                    height: '9.8%',
                },
                ko: {
                    top: '43%',
                    left: '50%',
                    width: '85.65%',
                    height: '9.7%',
                },
                'zh-CN': {
                    top: '44.05%',
                    left: '50%',
                    width: '85.72%',
                    height: '10%',
                },
                'zh-TW': {
                    top: '43.7%',
                    left: '50%',
                    width: '85.8%',
                    height: '10%',
                },
            },
            nextButton: {
                en: {
                    right: '7%',
                    top: '62.5%',
                    width: '86%',
                    height: '3.6%',
                },
                ko: {
                    right: '7%',
                    top: '61.7%',
                    width: '86%',
                    height: '3.5%',
                },
                ja: {
                    right: '7%',
                    top: '63.2%',
                    width: '86%',
                    height: '3.6%',
                },
                'zh-CN': {
                    right: '7%',
                    top: '62.4%',
                    width: '86%',
                    height: '3.6%',
                },
                'zh-TW': {
                    right: '7%',
                    top: '62.7%',
                    width: '86%',
                    height: '3.6%',
                },
            },
        },
        secondTab: {
            applyButtons: {
                en: {
                    korean: {
                        top: '77%',
                        left: '10.3%',
                        width: '35%',
                        height: '13.4%',
                    },
                    chinese: {
                        top: '77%',
                        right: '10.3%',
                        width: '35%',
                        height: '13.4%',
                    },
                },
                ja: {
                    korean: {
                        top: '75.6%',
                        left: '10.3%',
                        width: '35%',
                        height: '14.2%',
                    },
                    chinese: {
                        top: '75.6%',
                        right: '10.3%',
                        width: '35%',
                        height: '14.2%',
                    },
                },
                ko: {
                    korean: {
                        top: '77.0%',
                        left: '10.5%',
                        width: '35%',
                        height: '13.7%',
                    },
                    chinese: {
                        top: '77.0%',
                        right: '10.5%',
                        width: '35%',
                        height: '13.7%',
                    },
                },
                'zh-CN': {
                    korean: {
                        top: '76.8%',
                        left: '10.5%',
                        width: '35%',
                        height: '15%',
                    },
                    chinese: {
                        top: '76.8%',
                        right: '10.5%',
                        width: '35%',
                        height: '15%',
                    },
                },
                'zh-TW': {
                    korean: {
                        top: '75.3%',
                        left: '10.7%',
                        width: '35%',
                        height: '14.5%',
                    },
                    chinese: {
                        top: '75.3%',
                        right: '10.7%',
                        width: '35%',
                        height: '14.5%',
                    },
                },
            },
        },
        apply: {
            links: {
                en: {
                    link1: {
                        top: '32.4%',
                        left: '11%',
                        width: '37.5%',
                        height: '2.74%',
                        borderRadius: '30px',
                    },
                    link2: {
                        top: '32.4%',
                        right: '11%',
                        width: '37.5%',
                        height: '2.74%',
                        borderRadius: '30px',
                    },
                    link3: {
                        top: '36%',
                        left: '11%',
                        width: '37.5%',
                        height: '2.74%',
                        borderRadius: '30px',
                    }, // Japanese - bottom left
                    link4: {
                        top: '36%',
                        right: '11%',
                        width: '37.5%',
                        height: '2.74%',
                        borderRadius: '30px',
                    },
                },
                ko: {
                    link1: {
                        top: '30.73%',
                        left: '11%',
                        width: '37.5%',
                        height: '2.74%',
                        borderRadius: '30px',
                    },
                    link2: {
                        top: '30.73%',
                        right: '11%',
                        width: '37.5%',
                        height: '2.74%',
                        borderRadius: '30px',
                    },
                    link3: {
                        top: '34.35%',
                        left: '11%',
                        width: '37.5%',
                        height: '2.74%',
                        borderRadius: '30px',
                    }, // Japanese - bottom left
                    link4: {
                        top: '34.35%',
                        right: '11%',
                        width: '37.5%',
                        height: '2.74%',
                        borderRadius: '30px',
                    },
                },
                ja: {
                    link1: {
                        top: '30.4%',
                        left: '11%',
                        width: '37.5%',
                        height: '2.74%',
                        borderRadius: '30px',
                    },
                    link2: {
                        top: '30.4%',
                        right: '11%',
                        width: '37.5%',
                        height: '2.74%',
                        borderRadius: '30px',
                    },
                    link3: {
                        top: '34%',
                        left: '11%',
                        width: '37.5%',
                        height: '2.74%',
                        borderRadius: '30px',
                    },
                    link4: {
                        top: '34%',
                        right: '11%',
                        width: '37.5%',
                        height: '2.74%',
                        borderRadius: '30px',
                    },
                },
                'zh-CN': {
                    link1: {
                        top: '31.8%',
                        left: '11%',
                        width: '37.5%',
                        height: '2.78%',
                        borderRadius: '30px',
                    },
                    link2: {
                        top: '31.8%',
                        right: '11%',
                        width: '37.5%',
                        height: '2.78%',
                        borderRadius: '30px',
                    },
                    link3: {
                        top: '35.65%',
                        left: '11%',
                        width: '37.5%',
                        height: '2.78%',
                        borderRadius: '30px',
                    },
                    link4: {
                        top: '35.65%',
                        right: '11%',
                        width: '37.5%',
                        height: '2.78%',
                        borderRadius: '30px',
                    },
                },
                'zh-TW': {
                    link1: {
                        top: '31.5%',
                        left: '11%',
                        width: '37.5%',
                        height: '2.78%',
                        borderRadius: '30px',
                    },
                    link2: {
                        top: '31.5%',
                        right: '11%',
                        width: '37.5%',
                        height: '2.78%',
                        borderRadius: '30px',
                    },
                    link3: {
                        top: '35.2%',
                        left: '11%',
                        width: '37.5%',
                        height: '2.78%',
                        borderRadius: '30px',
                    },
                    link4: {
                        top: '35.2%',
                        right: '11%',
                        width: '37.5%',
                        height: '2.78%',
                        borderRadius: '30px',
                    },
                },
            },
        },
    },
};

// Create image maps

const contentImages = PHASE_IMAGES[CURRENT_PHASE].contentImages;
const tabBarImages = PHASE_IMAGES[CURRENT_PHASE].tabBarImages;

const applyContentImages = PHASE_IMAGES[CURRENT_PHASE].applyContentImages;

const faqImages = PHASE_IMAGES[CURRENT_PHASE].faqImages;

const externalLinksConfig = {
    ko: {
        link1: 'https://form.typeform.com/to/CyO2hYFS', // Korean form
        link2: 'https://form.typeform.com/to/jwge4Z2w', // English form
        link3: 'https://form.typeform.com/to/XCHsjWlj', // Japanese form
        link4: 'https://form.typeform.com/to/Biz1bvqk', // Chinese form
    },
    cn: {
        link1: 'https://form.typeform.com/to/IoJKlPGD', // Korean form
        link2: 'https://form.typeform.com/to/Zk88jRBc', // English form
        link3: 'https://form.typeform.com/to/t0kx96ZU', // Japanese form
        link4: 'https://form.typeform.com/to/N6Yl5BvD', // Chinese form
    },
};

export class ContentManager {
    constructor(urlManager) {
        this.urlManager = urlManager;
        this.previousState = null;
        this.preloadedImages = new Map();
        this.faqStates = {
            secondTab: false,
            apply: false,
        };

        this.preloadedVideos = {
            // Add this
            chinese: null,
            default: null,
        };

        this.preloadAllImages();
        this.preloadVideos();

        this.initializeContent();
        this.setupEventListeners();
    }

    preloadAllImages() {
        // Base images (common across all phases)
        ['first', 'second'].forEach((tab) => {
            Object.entries(contentImages[tab]).forEach(([locale, imageSrc]) => {
                this.preloadImage(`content_${tab}_${locale}`, imageSrc);
                this.preloadImage(
                    `tabbar_${tab}_${locale}`,
                    tabBarImages[tab][locale]
                );
            });
        });

        // Apply content images
        Object.entries(applyContentImages).forEach(
            ([applyLang, localeImages]) => {
                Object.entries(localeImages).forEach(([locale, imageSrc]) => {
                    this.preloadImage(
                        `apply_content_${applyLang}_${locale}`,
                        imageSrc
                    );
                });
            }
        );

        if (CURRENT_PHASE === 'PHASE_3') {
            // Phase 3 specific FAQ images
            ['closed', 'expanded'].forEach((state) => {
                // Second tab FAQ
                Object.entries(faqImages[state].secondTab).forEach(
                    ([locale, src]) => {
                        this.preloadImage(
                            `faq_${state}_secondTab_${locale}`,
                            src
                        );
                    }
                );

                // Apply FAQ for Korean and Chinese
                ['ko', 'cn'].forEach((applyLang) => {
                    Object.entries(faqImages[state].apply[applyLang]).forEach(
                        ([locale, src]) => {
                            this.preloadImage(
                                `faq_${state}_apply_${applyLang}_${locale}`,
                                src
                            );
                        }
                    );
                });
            });
        } else {
            // Non-Phase 3 FAQ images
            Object.entries(faqImages.default).forEach(([locale, src]) => {
                this.preloadImage(`faq_default_${locale}`, src);
            });

            Object.entries(faqImages.expanded.secondTab).forEach(
                ([locale, src]) => {
                    this.preloadImage(`faq_expanded_secondTab_${locale}`, src);
                }
            );

            ['ko', 'cn'].forEach((applyLang) => {
                Object.entries(faqImages.expanded.apply[applyLang]).forEach(
                    ([locale, src]) => {
                        this.preloadImage(
                            `faq_expanded_apply_${applyLang}_${locale}`,
                            src
                        );
                    }
                );
            });
        }
    }

    preloadImage(key, src) {
        const img = new Image();
        img.src = src;
        this.preloadedImages.set(key, img);
    }

    setupLanguageDropdown() {
        const dropdown = document.getElementById('language-dropdown');
        const selectedLang = document.getElementById('selected-lang');
        const select = document.getElementById('localeSelect');

        // Set initial language text
        const languageMap = {
            en: 'English',
            ja: '日本語',
            ko: '한국어',
            'zh-CN': '简体中文',
            'zh-TW': '繁體中文',
        };

        // Update selected language highlight
        const updateSelectedStyle = (currentLang) => {
            // Remove highlight from all options
            document.querySelectorAll('.dropdown-option').forEach((option) => {
                if (option.dataset.lang === currentLang) {
                    option.style.color = 'rgba(255, 255, 255, 1)';
                    option.style.fontWeight = '700';
                } else {
                    option.style.color = 'rgba(255, 255, 255, 0.4)';
                    option.style.fontWeight = '500';
                }
            });
        };

        // Initial setup
        const setSelectedText = () => {
            const currentLang = select.value;
            selectedLang.textContent = languageMap[currentLang];
            document.body.className = `lang-${currentLang}`;
            updateSelectedStyle(currentLang); // Add this line
        };
        setSelectedText();

        // Handle dropdown clicks
        dropdown.addEventListener('click', (e) => {
            if (e.target.classList.contains('dropdown-option')) {
                const lang = e.target.dataset.lang;
                // Update the hidden select
                select.value = lang;
                setSelectedText();
                dropdown.classList.remove('active');

                // Trigger change event on select
                select.dispatchEvent(new Event('change'));
            } else {
                dropdown.classList.toggle('active');
            }
        });

        // Close dropdown when clicking outside
        document.addEventListener('click', (e) => {
            if (!dropdown.contains(e.target)) {
                dropdown.classList.remove('active');
            }
        });
    }

    preloadVideos() {
        if (CURRENT_PHASE !== 'PHASE_3') {
            const container = document.querySelector('.youtube-container');
            if (!container) return;
            this.setupVideoContainer(container, 'first');
            return;
        }

        ['youtube-container', 'youtube-container2'].forEach((containerId) => {
            const container = document.querySelector(`.${containerId}`);
            if (!container) return;
            const videoType =
                containerId === 'youtube-container' ? 'first' : 'second';
            this.setupVideoContainer(container, videoType);
        });
    }

    setupVideoContainer(container, videoType) {
        const wrapper = document.createElement('div');
        wrapper.style.position = 'relative';
        wrapper.style.width = '100%';
        wrapper.style.height = '100%';
        container.appendChild(wrapper);

        ['chinese', 'default'].forEach((type) => {
            const iframe = document.createElement('iframe');
            iframe.width = '100%';
            iframe.height = '100%';
            iframe.src = videoConfig[type][videoType];
            iframe.frameBorder = '0';
            iframe.allowFullscreen = true;
            iframe.style.position = 'absolute';
            iframe.style.top = '0';
            iframe.style.left = '0';
            iframe.style.transition = 'opacity 0.3s ease';
            iframe.style.opacity = '0';
            iframe.style.display = 'none';

            wrapper.appendChild(iframe);
            if (!this.preloadedVideos[videoType]) {
                this.preloadedVideos[videoType] = {};
            }
            this.preloadedVideos[videoType][type] = iframe;
        });
    }

    updateVideoSource(locale) {
        const isChineseLang = ['zh-CN', 'zh-TW'].includes(locale);
        const activeType = isChineseLang ? 'chinese' : 'default';
        const inactiveType = isChineseLang ? 'default' : 'chinese';

        if (CURRENT_PHASE === 'PHASE_3') {
            ['first', 'second'].forEach((videoType) => {
                if (this.preloadedVideos[videoType]?.[activeType]) {
                    this.preloadedVideos[videoType][activeType].style.display =
                        'block';
                    this.preloadedVideos[videoType][activeType].style.opacity =
                        '1';
                    this.preloadedVideos[videoType][
                        inactiveType
                    ].style.opacity = '0';
                    this.preloadedVideos[videoType][
                        inactiveType
                    ].style.display = 'none';
                }
            });
        } else {
            // Original single video logic
            if (this.preloadedVideos.first?.[activeType]) {
                this.preloadedVideos.first[activeType].style.display = 'block';
                setTimeout(() => {
                    this.preloadedVideos.first[activeType].style.opacity = '1';
                }, 0);

                this.preloadedVideos.first[inactiveType].style.opacity = '0';
                setTimeout(() => {
                    this.preloadedVideos.first[inactiveType].style.display =
                        'none';
                }, 300);
            }
        }
    }

    updateTabBarImage(tab = 'first') {
        const locale = this.urlManager.getParam('locale') || 'en';
        const tabBarImage = document.getElementById('tabImage');

        const imageSrc = tabBarImages[tab]?.[locale];

        if (imageSrc) {
            tabBarImage.src = imageSrc;
            // Add aria-label for accessibility
            tabBarImage.setAttribute(
                'aria-label',
                `Switch to ${tab === 'first' ? 'second' : 'first'} tab`
            );
        } else {
            console.error(
                `No tab bar image found for tab: ${tab}, locale: ${locale}`
            );
        }
    }

    updateApplyImage(applyLang, locale) {
        const applyImage = document.getElementById('applyImage');

        if (applyLang && locale) {
            const imageSrc = applyContentImages[applyLang][locale];

            if (imageSrc && applyImage) {
                applyImage.src = imageSrc;
            } else {
                console.error(
                    `No apply content image found for applyLang: ${applyLang}, locale: ${locale}`
                );
            }
        } else if (!applyLang && locale) {
            // No applyLang provided, use the default content image
            const imageSrc = contentImages['first'][locale];
            if (imageSrc && applyImage) {
                applyImage.src = imageSrc;
            } else {
                console.error(`No content image found for locale: ${locale}`);
            }
        } else {
            console.error(
                'updateApplyImage: applyLang and locale parameters are required.'
            );
        }
    }

    updateExternalLinks(applyLang) {
        const links = externalLinksConfig[applyLang];
        if (!links) {
            console.error(`No links found for applyLang: ${applyLang}`);
            return;
        }

        const buttons = document.querySelectorAll(
            '#applyContent .action-button'
        );
        if (buttons.length >= 4) {
            buttons[0].onclick = () => window.open(links.link1); // Korean form
            buttons[1].onclick = () => window.open(links.link2); // English form
            buttons[2].onclick = () => window.open(links.link3); // Japanese form
            buttons[3].onclick = () => window.open(links.link4); // Chinese form
        }
    }

    updatePositions(locale) {
        this.updateFirstTabPositions(locale);
        this.updateSecondTabPositions(locale);
        this.updateApplyPositions(locale);
    }

    updateFirstTabPositions(locale) {
        const youtubeContainer = document.querySelector('.youtube-container');
        const youtubeContainer2 = document.querySelector('.youtube-container2');

        const youtubeStyle =
            styleConfig[CURRENT_PHASE].firstTab.youtube[locale];
        if (youtubeContainer && youtubeStyle) {
            Object.assign(youtubeContainer.style, youtubeStyle);
            youtubeContainer.style.background = '#ff000033'; // Debug
        }

        if (CURRENT_PHASE === 'PHASE_3' && youtubeContainer2) {
            const youtube2Style =
                styleConfig[CURRENT_PHASE].firstTab.youtube2[locale];
            if (youtube2Style) {
                Object.assign(youtubeContainer2.style, youtube2Style);
                youtubeContainer2.style.background = '#0000ff33'; // Debug
                youtubeContainer2.style.position = 'absolute'; // Add this
                youtubeContainer2.style.transform = 'translateX(-50%)'; // Add this
            }
        }

        const nextButton = document.querySelector(
            '#firstTabContent .action-button'
        );
        const nextButtonStyle =
            styleConfig[CURRENT_PHASE].firstTab.nextButton[locale];
        if (nextButton && nextButtonStyle) {
            Object.assign(nextButton.style, nextButtonStyle);
        }
    }

    updateSecondTabPositions(locale) {
        const buttons = document.querySelectorAll(
            '#secondTabContent .action-button'
        );
        const styles =
            styleConfig[CURRENT_PHASE].secondTab.applyButtons[locale];

        if (buttons.length >= 2 && styles) {
            Object.assign(buttons[0].style, styles.korean);
            Object.assign(buttons[1].style, styles.chinese);
        }
    }

    updateFaqImage(locale, context = 'secondTab') {
        const element = document.getElementById(`${context}Faq`);
        if (!element) return;

        const applyLang = this.urlManager.getParam('applyLang') || 'ko';
        const currentFaqImages = PHASE_IMAGES[CURRENT_PHASE].faqImages;
        let imageSrc;

        if (CURRENT_PHASE === 'PHASE_3') {
            const state = this.faqStates[context] ? 'expanded' : 'closed';
            imageSrc =
                context === 'apply'
                    ? currentFaqImages[state].apply[applyLang][locale]
                    : currentFaqImages[state].secondTab[locale];
        } else {
            imageSrc = this.faqStates[context]
                ? context === 'apply'
                    ? currentFaqImages.expanded.apply[applyLang][locale]
                    : currentFaqImages.expanded.secondTab[locale]
                : currentFaqImages.default[locale];
        }

        if (imageSrc) {
            element.src = imageSrc;
            element.style.cursor = 'pointer';
        }
    }

    updateApplyPositions(locale) {
        const buttons = document.querySelectorAll(
            '#applyContent .action-button'
        );
        const styles = styleConfig[CURRENT_PHASE].apply.links[locale];

        if (buttons.length >= 4 && styles) {
            Object.assign(buttons[0].style, styles.link1);
            Object.assign(buttons[1].style, styles.link2);
            Object.assign(buttons[2].style, styles.link3);
            Object.assign(buttons[3].style, styles.link4);
        }
    }

    initializeContent() {
        document.getElementById('mnetPlusLogo').src = mnetPlusLogo;

        const locale = this.urlManager.getParam('locale');
        const tab = this.urlManager.getParam('tab') || 'first';
        const applyLang = this.urlManager.getParam('applyLang');

        if (applyLang && !this.previousState) {
            this.previousState = {
                tab: 'second',
                locale,
            };
        }

        document.getElementById('localeSelect').value = locale;
        this.setupLanguageDropdown();
        this.updateTabBarImage(tab);
        this.showContent(tab, applyLang);
        this.updateContentImages(locale);
        this.updateApplyImage(applyLang, locale);
        this.updateBackButton(applyLang);
        this.updatePositions(locale);
        this.updateVideoSource(locale);

        // Initialize both FAQ images
        this.updateFaqImage(locale, 'secondTab');
        this.updateFaqImage(locale, 'apply');
    }

    setupEventListeners() {
        document
            .getElementById('localeSelect')
            .addEventListener('change', (e) => {
                const newLocale = e.target.value;
                this.urlManager.setParam('locale', newLocale);
                this.updateContentImages(newLocale);
                this.updateTabBarImage(
                    this.urlManager.getParam('tab') || 'first'
                );
                this.updatePositions(newLocale);
            });

        document.querySelector('.back-button').addEventListener('click', () => {
            this.handleBack();
        });

        // Add click handler for tab bar
        document.getElementById('tabImage').addEventListener('click', (e) => {
            // Get current tab
            const currentTab = this.urlManager.getParam('tab') || 'first';
            // Toggle between tabs
            const newTab = currentTab === 'first' ? 'second' : 'first';

            // Only switch if we're not in apply mode
            if (!this.urlManager.getParam('applyLang')) {
                window.app.switchTab(newTab);

                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                    // Using smooth behavior for a nicer transition
                });
            }
        });

        // Second Tab FAQ click handler
        document
            .getElementById('secondTabFaq')
            ?.addEventListener('click', () => {
                this.faqStates.secondTab = !this.faqStates.secondTab;
                const locale = this.urlManager.getParam('locale') || 'en';
                this.updateFaqImage(locale, 'secondTab');

                if (this.faqStates.secondTab) {
                    const faqElement = document.getElementById('secondTabFaq');
                    const offset = faqElement.offsetTop - 20; // Scroll to slightly above the FAQ
                    setTimeout(() => {
                        window.scrollTo({
                            top: offset,
                            behavior: 'smooth',
                        });
                    }, 100); // Small delay to ensure animation starts after FAQ expands
                }
            });

        // Apply FAQ click handler
        document.getElementById('applyFaq')?.addEventListener('click', () => {
            this.faqStates.apply = !this.faqStates.apply;
            const locale = this.urlManager.getParam('locale') || 'en';
            this.updateFaqImage(locale, 'apply');

            // Add scroll behavior when FAQ is expanded
            if (this.faqStates.apply) {
                const faqElement = document.getElementById('applyFaq');
                const offset = faqElement.offsetTop - 20; // Scroll to slightly above the FAQ
                setTimeout(() => {
                    window.scrollTo({
                        top: offset,
                        behavior: 'smooth',
                    });
                }, 100); // Small delay to ensure animation starts after FAQ expands
            }
        });
        // Update FAQ image when locale changes
        document
            .getElementById('localeSelect')
            .addEventListener('change', (e) => {
                const newLocale = e.target.value;
                this.urlManager.setParam('locale', newLocale);
                this.updateContentImages(newLocale);
                this.updateTabBarImage(
                    this.urlManager.getParam('tab') || 'first'
                );
                this.updatePositions(newLocale);
                this.updateVideoSource(newLocale); // Add this line

                // FAQ update logic
                const applyLang = this.urlManager.getParam('applyLang');
                if (applyLang) {
                    this.updateFaqImage(newLocale, 'apply');
                    this.updateApplyImage(applyLang, newLocale); // Update applyImage
                } else {
                    this.updateFaqImage(newLocale, 'secondTab');
                }
            });
    }

    updateContentImages(locale) {
        const firstTabImageSrc = contentImages.first[locale];
        const secondTabImageSrc = contentImages.second[locale];

        if (firstTabImageSrc) {
            document.getElementById('firstTabImage').src = firstTabImageSrc;
        }

        if (secondTabImageSrc) {
            document.getElementById('secondTabImage').src = secondTabImageSrc;
        }
    }

    showContent(tab, applyLang) {
        // Hide all content sections first
        document.getElementById('firstTabContent').style.display = 'none';
        document.getElementById('secondTabContent').style.display = 'none';
        document.getElementById('applyContent').style.display = 'none';

        // Reset FAQ states when switching content
        this.faqStates.secondTab = false;
        this.faqStates.apply = false;

        // Handle tab visibility
        const tabsElement = document.querySelector('.tabs');
        if (applyLang) {
            this.updateExternalLinks(applyLang);
            tabsElement.style.display = 'none';
            document.getElementById('applyContent').style.display = 'block';
            window.scrollTo({ top: 0, behavior: 'smooth' });
        } else {
            tabsElement.style.display = 'block';
            document.getElementById(`${tab}TabContent`).style.display = 'block';
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }

        // Update FAQ images for both contexts
        const locale = this.urlManager.getParam('locale') || 'en';
        this.updateFaqImage(locale, 'secondTab');
        this.updateFaqImage(locale, 'apply');
        this.updateApplyImage(applyLang, locale);
    }

    updateBackButton(applyLang) {
        const logo = document.querySelector('.company-logo');
        const backButton = document.querySelector('.back-button');

        if (this.isMobileWebView()) {
            logo.style.display = 'none';
            backButton.style.display = 'inline-block';

            // Remove existing listeners
            backButton.replaceWith(backButton.cloneNode(true));
            const newBackButton = document.querySelector('.back-button');

            if (applyLang) {
                // When on apply page
                newBackButton.addEventListener('click', () =>
                    this.handleBack()
                );
            } else {
                // Only add close handler when not navigating back
                newBackButton.addEventListener(
                    'click',
                    this.handleWebViewClose
                );
            }
        } else {
            // Regular web handling...
            if (applyLang) {
                logo.style.display = 'none';
                backButton.style.display = 'inline-block';
                backButton.addEventListener('click', () => this.handleBack());
            } else {
                logo.style.display = 'inline-block';
                backButton.style.display = 'none';
                logo.addEventListener('click', this.handleLogoClick);
            }
        }
    }
    isMobileWebView() {
        return (
            typeof window.MnetPlusApp !== 'undefined' &&
            window.MnetPlusApp.startsWith('Mnet Plus/')
        );
    }

    handleWebViewClose = () => {
        window.ReactNativeWebView.postMessage('{"command":"close"}');
    };

    handleLogoClick = () => {
        window.open('https://mnetplus.world/main', '_blank');
    };

    storePreviousState() {
        this.previousState = {
            tab: this.urlManager.getParam('tab') || 'first',
            locale: this.urlManager.getParam('locale'),
        };
    }

    handleBack() {
        if (this.previousState) {
            // Show tabs when going back
            document.querySelector('.tabs').style.display = 'block';

            this.urlManager.setParam('applyLang', null);
            this.urlManager.setParam('tab', this.previousState.tab);

            this.updateTabBarImage(this.previousState.tab);
            this.showContent(this.previousState.tab, null);

            // Just update UI without adding new event listeners yet
            const logo = document.querySelector('.company-logo');
            const backButton = document.querySelector('.back-button');

            if (this.isMobileWebView()) {
                logo.style.display = 'none';
                backButton.style.display = 'inline-block';
            } else {
                logo.style.display = 'inline-block';
                backButton.style.display = 'none';
            }

            this.previousState = null;

            // Now add the close handler after navigation is complete
            if (this.isMobileWebView()) {
                setTimeout(() => {
                    backButton.replaceWith(backButton.cloneNode(true));
                    const newBackButton =
                        document.querySelector('.back-button');
                    newBackButton.addEventListener(
                        'click',
                        this.handleWebViewClose
                    );
                }, 0);
            }
        }
    }
}
