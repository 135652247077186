const PHASE2_START = new Date('2025-01-26T15:00:00Z'); // Jan 27 2025 00:00 KST (1차 모집 마감)
const PHASE3_START = new Date('2025-02-02T15:00:00Z'); // Feb 3 2025 00:00 KST (2차 모집 시작)

export function getCurrentPhase() {
    const now = new Date();

    if (now < PHASE2_START) {
        return 'PHASE_1'; // Before Jan 27 2025 00:00 KST - First registration period
    } else if (now < PHASE3_START) {
        return 'PHASE_2'; // Between Jan 27 00:00 KST and Feb 3 00:00 KST - Intermediate period
    } else {
        return 'PHASE_3'; // After Feb 3 00:00 KST - Second registration period
    }
}

export async function getVideoId() {
    const response = await fetch(
        'https://www.googleapis.com/youtube/v3/search?part=snippet&channelId=UCQdZltW7arY6tJ0IvZw8hUw&maxResults=1&order=date&type=video&key=AIzaSyD-1J1J9Q6J9Q1J9Q1J9Q1J9Q1J9Q1J9Q1'
    );
    console.log('response', response);
}
