import { URLManager } from './URLManager';
import { ContentManager } from './ContentManager';
import { getCurrentPhase } from './scheduleHelper';
import PHASE1_ALERT_MESSAGE from '../messages/phase-1-alert.json';

function disablePinchZoom() {
    document.addEventListener(
        'touchstart',
        function (e) {
            if (e.touches.length > 1) {
                e.preventDefault();
            }
        },
        { passive: false }
    );
    document.addEventListener(
        'touchmove',
        function (e) {
            if (e.scale && e.scale !== 1) {
                e.preventDefault();
            }
        },
        { passive: false }
    );
}

disablePinchZoom();

const urlManager = new URLManager();
const contentManager = new ContentManager(urlManager);

// Define the handler function in the global scope
function handleClickOnApplyButton() {
    const currentPhase = getCurrentPhase();

    if (currentPhase === 'PHASE_2') {
        const locale = urlManager.getParam('locale');
        alert(PHASE1_ALERT_MESSAGE[locale]); // Korean
    } else {
        window.app.switchTab('second');
    }
}

// Make it available globally
window.handleClickOnApplyButton = handleClickOnApplyButton;

window.app = {
    switchTab: (tab) => {
        urlManager.setParam('tab', tab);
        urlManager.setParam('applyLang', null);
        contentManager.updateTabBarImage(tab); // Updated method name
        contentManager.showContent(tab, null);
    },
    showApply: (lang) => {
        contentManager.storePreviousState();
        urlManager.setParam('applyLang', lang);
        contentManager.showContent(null, lang);
        contentManager.updateBackButton(lang);
    },
};
