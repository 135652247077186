import { getCurrentPhase } from './scheduleHelper';

const CURRENT_PHASE = getCurrentPhase();

const metadataConfig = {
    ko: {
        title: `BOYS 2 PLANET | 공식 홈페이지 | Mnet`,
        description: `Mnet ‘Boys Planet, Girls Planet, I-LAND 시즌1 & 시즌2’ 제작진의 초대형 보이 그룹 오디션 프로젝트 <BOYS 2 PLANET>
세계 최초 K-POP GLOBAL TWIN PROJECT`,
        keywords: `플래닛비, 플래닛 B, 플래닛B, PLANET B, Mnet 플래닛B, 엠넷 플래닛B, Mnet 오디션, Mnet 서바이벌, PLANET B 홈페이지, PLANET B 라인업, PLANET B 출연자, PLANET B 방송정보, PLANET B 홈페이지, PLANET B 공홈, PLANET B 영상, PLANET B 클립, PLANET B 투표, PLANET B 지원, 플래닛B 홈페이지, 플래닛B 라인업, 플래닛B 출연자, 플래닛B 방송정보, 플래닛B 홈페이지, 플래닛B 공홈, 플래닛B 영상, 플래닛B 클립, 플래닛B 투표, 플래닛B 지원, 보이즈플래닛, 걸스플래닛, 보이즈플래닛2, 아이랜드, 아이랜드2, BOYS PLANET, BOYS PLANET2, ILAND, I-LAND, I-LAND2, Girls Planet, boys 2 planet`,
        ogTitle: 'BOYS 2 PLANET | 공식 홈페이지 | Mnet',
        ogDescription: `Mnet ‘Boys Planet, Girls Planet, I-LAND 시즌1 & 시즌2’ 제작진의 초대형 보이 그룹 오디션 프로젝트 <BOYS 2 PLANET>
세계 최초 K-POP GLOBAL TWIN PROJECT`,
    },
    default: {
        title: 'BOYS 2 PLANET | Official Website | Mnet',
        description: `From the producers of Mnet's ‘Boys Planet, Girls Planet, and I-LAND Season 1 & Season 2’, comes a mega boy group audition project <BOYS 2 PLANET>
The World's First GLOBAL K-POP TWIN PROJECT`,
        keywords: `PLANET B, Mnet PLANET B, Mnet audition, Mnet survival, PLANET B lineup, PLANET B cast, PLANET B broadcast information, PLANET B homepage, PLANET B video, PLANET B clip, PLANET B vote, BOYS PLANET, BOYS PLANET2, ILAND, I-LAND, I-LAND2, Girls Planet, boys 2 planet`,
        ogTitle: `BOYS 2 PLANET | Official Website | Mnet`,
        ogDescription: `From the producers of Mnet's ‘Boys Planet, Girls Planet, and I-LAND Season 1 & Season 2’, comes a mega boy group audition project <BOYS 2 PLANET>
The World's First GLOBAL K-POP TWIN PROJECT`,
    },
};

export class URLManager {
    constructor() {
        this.params = new URLSearchParams(window.location.search);
        this.validateAndUpdateParams();

        // Initial metadata update
        this.updateMetadata(this.getParam('locale'));
    }

    validateAndUpdateParams() {
        const validLocales = ['en', 'ja', 'ko', 'zh-CN', 'zh-TW'];
        let locale = this.params.get('locale');
        if (!locale || !validLocales.includes(locale)) {
            locale = 'en';
            this.params.set('locale', locale);
        }

        // Remove applyLang if in PHASE_2
        if (CURRENT_PHASE === 'PHASE_2') {
            this.params.delete('applyLang');
        } else {
            // Handle applyLang for other phases
            const applyLang = this.params.get('applyLang');
            if (applyLang && !['ko', 'cn'].includes(applyLang)) {
                this.params.set('applyLang', 'ko');
            }
        }

        // Handle tab parameter
        const tab = this.params.get('tab');
        const validTabs =
            CURRENT_PHASE === 'PHASE_2' ? ['first'] : ['first', 'second'];

        if (!this.params.get('applyLang')) {
            if (!tab || !validTabs.includes(tab)) {
                this.params.set('tab', 'first');
            }
        }

        this.updateURL();
    }

    updateURL() {
        const newURL = `${window.location.pathname}?${this.params.toString()}`;
        window.history.replaceState({}, '', newURL);
    }

    getParam(key) {
        return this.params.get(key);
    }

    setParam(key, value) {
        if (value === null) {
            this.params.delete(key);
        } else {
            this.params.set(key, value);
        }
        this.updateURL();

        // Update metadata when locale changes
        if (key === 'locale') {
            this.updateMetadata(value);
        }
    }

    updateMetadata(locale) {
        const metadata =
            locale === 'ko' ? metadataConfig.ko : metadataConfig.default;

        // Update meta description
        const metaDescription = document.querySelector(
            'meta[name="description"]'
        );
        if (metaDescription) {
            metaDescription.setAttribute('content', metadata.description);
        } else {
            console.error('Meta tag for description not found');
        }

        // Update Open Graph tags
        const ogTitle = document.querySelector('meta[property="og:title"]');
        if (ogTitle) {
            ogTitle.setAttribute('content', metadata.ogTitle);
        } else {
            console.error('Meta tag for og:title not found');
        }

        const ogDescription = document.querySelector(
            'meta[property="og:description"]'
        );
        if (ogDescription) {
            ogDescription.setAttribute('content', metadata.ogDescription);
        } else {
            console.error('Meta tag for og:description not found');
        }

        // Update keywords
        const keywords = document.querySelector('meta[name="keywords"]');
        if (keywords) {
            keywords.setAttribute('content', metadata.keywords);
        } else {
            console.error('Meta tag for keywords not found');
        }

        // Update title tag
        const title = document.querySelector('title');
        if (title) {
            title.textContent = metadata.title;
        } else {
            console.error('Title tag not found');
        }
    }
}
